<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService" 
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages" />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/usBucketService'
import usBucketPDService from '../api/usBucketPDService'
import usBucketCountryService from '../api/usBucketCountryService'

      
export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getUsBucketPDMeta() {
      const usBucketPDMeta = await usBucketPDService.getMetaWithValidation()
      helpers.processMetaDetails(
        usBucketPDMeta,
        'usBucketPD',
        'usBucketId',
        'usBucket',
        usBucketPDService
      )
      return usBucketPDMeta;
    },
    async getUsBucketCountryMeta() {
      const usBucketCountryMeta = await usBucketCountryService.getMetaWithValidation()
      helpers.processMetaDetails(
        usBucketCountryMeta,
        'usBucketCountry',
        'usBucketId',
        'usBucket',
        usBucketCountryService
      )
      return usBucketCountryMeta;
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getUsBucketPDMeta(),
        this.getUsBucketCountryMeta(),
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'app.pages')
      this.metadata = meta
    })
  }
}
</script>
